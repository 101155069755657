<template>
  <div class="size-full">
    <div class="flex size-full flex-col transition-[padding] duration-300 ease-[ease]" :class="containerClass">
      <AppHeader class="flex-none" />

      <div class="flex-1">
        <slot />
      </div>
    </div>
    <AppAside />

    <ConfirmDialog />
    <ConfirmDialog group="dialog" />
    <ConfirmPopup group="popup" />
    <Toast position="bottom-center" />
    <UniverseChoicePopup />
  </div>
</template>

<script setup>
const { asdieCollapsed, asideRtlMode } = storeToRefs(useLayoutStore());
import UniverseChoicePopup from '~/components/UniverseChoicePopup.vue';

const containerClass = computed(() => ({
  'pl-[65px]': asdieCollapsed.value && !asideRtlMode.value,
  'pl-[200px]': !asdieCollapsed.value && !asideRtlMode.value,
  'pr-[65px]': asdieCollapsed.value && asideRtlMode.value,
  'pr-[200px]': !asdieCollapsed.value && asideRtlMode.value,
}));
</script>
